<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索与添加区 -->
    <el-row :gutter="24"> <!-- 行 -->
      <el-col :span="24"> <!-- 列 -->
        <el-button type="primary" @click="addUserClick" plain>新增</el-button>
      </el-col>
    </el-row>
    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="roleList"
      v-bind="otherProps"
      :listCount="roleTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #isAdminSlot="scope">
        <el-tag v-if="scope.row.is_admin">是</el-tag>
        <el-tag v-else  type="info">否</el-tag>
      </template>
      <template #operation="scope">
        <span @click="editUserInfoClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-role-drawer :show="addEditRoleDrawerShow" :params="editUserInfo" @close="addEditRoleDrawerClose"></add-edit-role-drawer>
  </div>
</template>
<script>
import TableUi from '@/components/table-ui/table-ui.vue'
import AddEditRoleDrawer from './cnps/add-edit-role-drawer.vue'
import { propList, operationProps, otherProps } from './config/roleConfig'
import { mapState, mapActions } from 'vuex'
import { deleteRole } from '@/api/adminUser/adminUser'
export default {
  components: {
    TableUi,
    AddEditRoleDrawer
  },
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      queryInfo: {
        cur: 1,
        size: 10
      },
      addEditRoleDrawerShow: false,
      editUserInfo: null,
    }
  },
  created() {
    this.getUserRoleList()
  },
  methods: {
    ...mapActions('role', ['getUserRoleList']),
    getList() {
      this.getUserRoleList(this.queryInfo)
    },
    searchDataClick() {
      this.queryInfo.cur = 1
      this.getList()
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getList()
    },
    addUserClick() {
      this.addEditRoleDrawerShow = true;
    },
    async deleteClick (id) {
      const result = await deleteRole(id)
      if (result.success) {
        this.$message.success('已删除')
        this.getList()
      }
    },
    editUserInfoClick(row) {
      this.editUserInfo = row
      this.addEditRoleDrawerShow = true
    },
    addEditRoleDrawerClose () {
      this.searchDataClick()
      this.addEditRoleDrawerShow = false;
      this.editUserInfo = null;
    },
  },
  computed: {
    ...mapState('role', ['roleTotal', 'roleList']),
  },
}
</script>
<style scoped>
.search-input {
  width: 160px;
  margin-right: 10px;
}
.samllImg {
  width: 50px;
  height: 50px;
}
</style>

