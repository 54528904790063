<template>
  <div>
    <el-drawer :visible.sync="show" :before-close="close" size="30%" :modal="false"  :with-header="false" @open="openDrawer">
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="80px">
        <el-form-item label="角色名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="permission">
          <el-tree
            ref="permissionRef"
            :props="treeRrops"
            :data="routeList"
            show-checkbox
            node-key="id"
            :default-expanded-keys="permissionExpanded"
            :default-checked-keys="permissionChecked"
            @check-change="permissionCheckedChange">
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import navigation from '@/utils/navigation'
import { editRole, addRole } from '@/api/adminUser/adminUser'
export default {
  components: {
    
  },
  data() {
    return {
      ruleForm: {
        id: '',
        name: '',
        permission: []
      },
      treeRrops: {
        label: 'title',
        children: 'list'
      },
      routeList: [],
      permissionExpanded: [], // 权限树展开id列表
      permissionChecked: [], // 权限树选中id列表
    }
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    params: {
      required: false,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: '',
        name: '',
        permission: []
      }
      this.$emit('close')
    },
    openDrawer () {
      this.permissionChecked = []
      if (this.params) {
        this.ruleForm.id = this.params.id
        this.ruleForm.name = this.params.name
        this.ruleForm.permission = this.params.permission
        this.params.permission.forEach(item => {
          this.permissionChecked.push(item.id)
        })
      } else {
        this.ruleForm.id = ''
      }
      const list = navigation(true)
      this.routeList = list.filter(v => !v.isAdmin)
    },
    permissionCheckedChange () {
      this.ruleForm.permission = this.$refs.permissionRef.getCheckedKeys().map(v => {
        return {
          id: v
        }
      })
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.ruleForm.id ? await editRole({
          id: this.ruleForm.id,
          name: this.ruleForm.name,
          permission: this.ruleForm.permission
        }) : await addRole({
          name: this.ruleForm.name,
          permission: this.ruleForm.permission
        })
        if (result.success) {
          this.$message.success('修改成功')
          this.close()
        }
      })
    },
  },
  computed: {
    ...mapState('adminUser', ['roleList']),
  },
}
</script>
<style scoped lang="less">

</style>
